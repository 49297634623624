var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        background: "#f0f2f5",
        "margin-top": "-20px",
        height: "100%"
      }
    },
    [
      _c("div", { staticClass: "wscn-http404" }, [
        _c("div", { staticClass: "pic-404" }, [
          _c("img", {
            staticClass: "pic-404__parent",
            attrs: { src: _vm.img_404, alt: "404" }
          }),
          _c("img", {
            staticClass: "pic-404__child left",
            attrs: { src: _vm.img_404_cloud, alt: "404" }
          }),
          _c("img", {
            staticClass: "pic-404__child mid",
            attrs: { src: _vm.img_404_cloud, alt: "404" }
          }),
          _c("img", {
            staticClass: "pic-404__child right",
            attrs: { src: _vm.img_404_cloud, alt: "404" }
          })
        ]),
        _vm._m(0)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bullshit" }, [
      _c("div", { staticClass: "bullshit__headline" }, [
        _vm._v("抱歉，你访问的页面不存在")
      ]),
      _c("a", { staticClass: "bullshit__return-home", attrs: { href: "/" } }, [
        _vm._v("返回首页")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }